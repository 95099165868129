.banner-box {
  position: relative;
  width: 100%; 
}

.image {
  display: flex;
  width: 100%; 
  height: 700px; 
  img {
    object-fit: cover;
    height: 700px;
    width: 100%;
  }
}

.introduction {
  color: white;
  position: absolute;
  top: 200px;
  right: 100px;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2,
  p {
    margin: 5px;
  }
}

@media (max-width: 1000px) {
  .introduction{
    h2 {
      font-size: 50px;
    }
    p {
      font-size: 25px;
    }

  }
  
}

@media (max-width: 700px) {
  .introduction{
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
    }

  }
}

@media (max-width: 563px) {
  .introduction{
    width: 100%;
    right: 0px;
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 15px;
    }

  }
  .image {
    height: auto;
    img {
      height: 430px;
      
    }
  }
}
@media (max-width: 390px) {
  .introduction {
    width: 100%;
    right: 0px;
  }
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 15px;
  }
  .image {
    height: auto;
    img {
      height: 430px;
      
    }
  }
}