.card {
  background-color: black;
  width: 400px;
  height: 650px;
  border-radius: 15px;
  //margin: 5px;
  box-shadow: 0 0 10px 5px rgb(192, 190, 190);
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: 250px;
    //object-fit: cover;
    border-radius: 15px 15px 0 0;
  }
  h2,
  p {
    color: white;
    text-align: center;
    margin: 8px;
  }
  .competence {
    font-size: 20px;
    font-weight: bold;
  }
  .icons {
    display: flex;
    img {
      height: 70px;
      width: 70px;
    }
  }

 
}
// Média-query pour les écrans de petite taille
@media (max-width: 395px) {
    .card {
      width: 356px;
      height: 650px;
    }
  }
