.error{
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    background-color: #000;

    h1{
        color: #c58ac1;
        text-align: center;
        font-size: 288px;
        font-style: normal;
        font-weight: 700;
        margin: 0px;
        
    }
    h2{
        color:  #c58ac1;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        margin: 0px;
        

    }
    h3{
        color:white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        text-decoration-line: underline;
        margin-bottom: 100px;
    }

}
@media (max-width:768px) {
    .error{
        h1{
            font-size: 96px;
        }
        h2{
            font-size: 18px;
        }
        h3{
            font-size: 14px;
        }


    }
   
   
}

@media (max-width:482px) {

}