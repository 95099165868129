html {
  scroll-behavior: smooth;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Ajoute une ombre douce */
  background-color: white;
}

.block-logo {
  display: flex;
}

.image-header {
  margin-top: 28px;
  width: 50px;
  height: 50px;
}

h1 {
  font-size: 40px;
  display: flex;
  align-items: center;
  color: rgb(58, 57, 57);
}

.link {
  display: flex;
  align-items: center;
  gap: 60px;
  font-weight: bold;
  font-size: 20px;
}
.link :hover {
  color: violet;
}
.link a {
  text-decoration: none;
  color: black;
}

@media (max-width: 1360px) {
  .link {
    font-size: 20px;
  }
}
@media (max-width: 550px) {
  .link {
    font-size: 15px;
    gap: 30px;
  }
  h1 {
    font-size: 20px;
  }
  .image-header {
    margin-top: 19px;
    margin-right: 1px;
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 400px) {
  .link {
    font-size: 12px;
    gap: 20px;
  }
  h1 {
    font-size: 20px;
  }
}/*# sourceMappingURL=header.css.map */